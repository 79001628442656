<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import store from "@/store/index.js";
export default {
  name: "App",
  metaInfo() {
    return {
      title: this.$store.state.settings.dynamicTitle && this.$store.state.settings.title,
      titleTemplate: (title) => {
        return title
          ? `${title} - ${process.env.VUE_APP_TITLE}`
          : process.env.VUE_APP_TITLE;
      },
    };
  },
  watch: {
    $route(to, from) {
      if (
        to.path.indexOf("/user/login") == -1 &&
        store.state.webSocket.socketTask === null &&
        !store.state.webSocket.lockReconnect
      ) {
        this.$SOCKET.initWebSocket();
      }
    },
  },
  created() {
    window.addEventListener("beforeunload",()=>{
      let path = this.$route.path
      sessionStorage.setItem('beforeunload-path',JSON.stringify(path))
    })
  },
  mounted() {
    //   刷新页面后webSocket断开，重新链接
    // if(this.$route.path != '/' && store.state.webSocket.socketTask === null && !store.state.webSocket.lockReconnect){
    //     console.log('App page - webSocket = ', store.state.webSocket.socketTask, store.state.webSocket.lockReconnect)
    //
    // }
    // if(window.screen.width > 1200){
    // window.onload = window.onresize = function(){
    // this.bodyScale() // 进入程序按比例缩放
    // }
    // }
  },
  methods: {
    bodyScale() {
      let deviceWidth = document.documentElement.clientWidth;
      console.log("APP 分辨率", deviceWidth);
      let scale = deviceWidth / 1880;
      document.body.style.zoom = scale;
    },
  },
};
</script>
<style lang="scss">
@import "assets/styles/flex.css";
@import "assets/styles/commonStyles.css";
.el-input-sunyun-input {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-input__inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
.sunyun-select-tags,
.select-all-filter {
  background-color: #ffffff !important;
  padding-left: 0px !important;
  .leftText {
    color: #ffffff !important;
    background-color: #a7b5d3 !important;
    padding: 0 4px !important;
    font-size: 12px !important;
  }
  .rightText {
    color: #333333 !important;
    font-size: 12px !important;
    padding: 0 4px !important;
    padding-right: 2px !important;
  }
}

.topLoading {
  z-index: 7778 !important;
}
//内容颜色-白色
.whiteColor {
  color: #ffffff;
  border: none;
}

//背景颜色-白色
.bg-Color {
  background: #ffffff;
}
.inputWidth {
  width: 250px !important;
}

.pointer {
  cursor: pointer;
}

.el-dropdown .el-button-group {
  display: flex !important;
  .el-button {
    height: 28px !important;
  }
}

.pagination-footer {
  padding: 0 20px;
  height: 50px;
  .left {
    padding: 32px 16px;
    .num {
      color: #1890ff;
      margin: 0 10px;
    }
  }
  .checkAll {
    color: #1890ff;
    cursor: pointer;
  }
  .total {
    margin: 0 10px;
  }
}

/* 进度条宽高 */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* 进度条背景样式 */
::-webkit-scrollbar-track {
  background: rgb(242, 242, 242);
}

/* 进度条样式 */
::-webkit-scrollbar-thumb {
  background: rgb(180, 187, 197);
  border-radius: 10px;
}

/* 进度条鼠标悬浮样式 */
::-webkit-scrollbar-thumb:hover {
  background: rgb(129, 129, 129);
}

/* 进度条拖动样式 */
::-webkit-scrollbar-thumb:active {
  background: rgb(96, 96, 96);
}

/* 文本省略 */
.omit {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* 文本省略 一行 */
.omit1 {
  //text-overflow: -o-ellipsis-lastline;
  overflow: hidden; //溢出内容隐藏
  text-overflow: ellipsis; //文本溢出部分用省略号表示
  display: -webkit-box; //特别显示模式
  -webkit-line-clamp: 1; //行数
  line-clamp: 1;
  -webkit-box-orient: vertical; //盒子中内容竖直排列
}

/* mini */
.vueTreeSelectMini .vue-treeselect__control {
  height: 26px !important;
}
.vueTreeSelectMini .vue-treeselect__control .vue-treeselect__value-container {
  height: 26px !important;
  display: block !important;
}
.vueTreeSelectMini
  .vue-treeselect__control
  .vue-treeselect__value-container
  .vue-treeselect__placeholder {
  line-height: 26px !important;
  font-size: 12px !important;
}
.vueTreeSelectMini
  .vue-treeselect__control
  .vue-treeselect__value-container
  .vue-treeselect__single-value {
  line-height: 26px !important;
  font-size: 12px !important;
}
.el-date_form {
  .el-year-table td.current:not(.disabled) .cell {
    color: #1890ff;
    font-weight: bold !important;
    background-color: var(
      --el-datepicker-active-color
    ); // 这里应用的是项目的默认样式颜色，跟整个系统保持统一
    border-radius: 100px;
  }
  .el-year-table td.today .cell {
    font-weight: normal;
    color: #606266;
  }
  .el-month-table td.current:not(.disabled) .cell {
    color: #1890ff;
    font-weight: bold !important;
    background-color: var(--el-datepicker-active-color);
    border-radius: 100px;
  }
  .el-month-table td.today .cell {
    font-weight: normal;
    color: #606266;
  }
  .el-date-table td.today .el-date-table-cell__text {
    color: #606266;
    font-weight: normal;
  }
  .el-date-table td.current:not(.disabled) .el-date-table-cell__text {
    color: #ffffff;
    font-weight: bold !important;
    background-color: var(--el-datepicker-active-color);
  }
}
</style>
