import request from '@/utils/request'

// 门店报损列表
export function listOrder (query) {
  return request({
    url: '/api/system/decoration/room/order/list',
    method: 'get',
    params: query
  })
}
// 获取制作工序
export const getOrderDetailIds = (data = {}) => request({ method: 'post', url: '/api/system/decoration/room/delivery/getOrderDetailIds', data });

// 批量新增裱花订单明细（未制作的单据）
export const UpdateBatchSaveOrder = (data = {}) => request({ method: 'post', url: '/api/system/decoration/room/delivery/save', data });

// 将订单批量配送
export const deliveryShopBatchOrder = (data = {}) => request({ method: 'post', url: '/api/system/decoration/room/delivery/deliveryShopBatchOrder', data });

// 将订单批量调至指定门店
export const deliveryShopBatchUpdate = (data = {}) => request({ method: 'post', url: '/api/system/decoration/room/delivery/deliveryShopBatchUpdate', data });