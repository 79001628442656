import request from '@/utils/request'

// 裱花工序列表
export function listProcess (query) {
  return request({
    url: '/api/system/decoration/process/list',
    method: 'get',
    params: query
  })
}
// 新增裱花工序
export function addProcess (data) {
    return request({
      url: '/api/system/decoration/process/save',
      method: 'post',
      data
    })
  }
  
  // 修改裱花工序
  export function updateProcess (data) {
    return request({
      url: '/api/system/decoration/process/update',
      method: 'put',
      data: data
    })
  }
  
  // 删除裱花工序
  export function delProcess (processIds) {
    return request({
      url: '/api/system/decoration/process/delete',
      method: 'delete',
      data: processIds
    })
  }
  
  //还原裱花工序
  export function decorationProcess (workerId) {
    return request({
      url: '/api/system/decoration/process/reduction',
      method: 'post',
      data: workerId
    })
  }